import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layouts"
import Seo from "../components/seo"

import { LANG_IT } from "../utils/localization"
import { SiteInformation } from "../utils/site"

const TermsPage: React.FC<PageProps<SiteInformation>> = ({ data, path }) => (
  <Layout lang={LANG_IT}>
    <Helmet>
      <link
        rel="alternate"
        hrefLang={"x-default"}
        href={`${data.site.siteMetadata.siteUrl}/terms/`}
      />
      <link
        rel="alternate"
        hrefLang={LANG_IT}
        href={`${data.site.siteMetadata.siteUrl}/it/terms/`}
      />
    </Helmet>
    <Seo
      title="Termini e Condizioni"
      lang={LANG_IT}
      description="Scaricando o utilizzando l'app, questi termini si applicheranno automaticamente a te: assicurati quindi di leggerli attentamente prima di utilizzare l'app"
      path={path}
    />
    <section id="main">
      <div className="container">
        <h1>Termini e Condizioni</h1>
        <p>
          Scaricando o utilizzando l'app, questi termini si applicheranno
          automaticamente a te: assicurati quindi di leggerli attentamente prima
          di utilizzare l'app. Non è consentito copiare o modificare in alcun
          modo l'app, qualsiasi parte dell'app o i nostri marchi. Non puoi
          tentare di estrarre il codice sorgente dell'app e non dovresti nemmeno
          provare a tradurre l'app in altre lingue o creare versioni derivate.
          L'app stessa e tutti i marchi, i diritti d'autore, i diritti sui
          database e altri diritti di proprietà intellettuale ad essa correlati
          appartengono ancora a Caplaz.
        </p>
        <p>
          Caplaz si impegna a garantire che l'app sia il più utile ed efficiente
          possibile. Per tale motivo, ci riserviamo il diritto di apportare
          modifiche all'app o di addebitarne i servizi, in qualsiasi momento e
          per qualsiasi motivo. Non ti addebiteremo mai l'app o i suoi servizi
          senza chiarirti esattamente per cosa stai pagando.
        </p>
        <p>
          L'app Doodle+ memorizza ed elabora i dati personali che ci hai fornito
          per fornire il nostro Servizio. È tua responsabilità proteggere il tuo
          telefono e l'accesso all'app. Ti consigliamo quindi di non eseguire il
          jailbreak o il root del tuo telefono, che è il processo di rimozione
          delle restrizioni e limitazioni software imposte dal sistema operativo
          ufficiale del tuo dispositivo. Potrebbe rendere il tuo telefono
          vulnerabile a malware/virus/programmi dannosi, compromettere le
          funzionalità di sicurezza del tuo telefono e potrebbe significare che
          l'app Doodle+ non funzionerà correttamente o del tutto.
        </p>
        <div>
          <p>
            L'app utilizza servizi di terze parti che dichiarano i propri
            Termini e condizioni.
          </p>
          <p>
            Collegamento a Termini e condizioni di fornitori di servizi di terze
            parti utilizzati dall'app
          </p>
          <ul>
            <li>
              <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Play Services
              </a>
            </li>
            <li>
              <a
                href="https://firebase.google.com/terms/analytics"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Analytics for Firebase
              </a>
            </li>
            <li>
              <a
                href="https://firebase.google.com/terms/crashlytics"
                target="_blank"
                rel="noopener noreferrer"
              >
                Firebase Crashlytics
              </a>
            </li>
          </ul>
        </div>
        <p>
          Dovresti essere consapevole che ci sono alcune cose di cui Caplaz non
          si assumerà la responsabilità. Alcune funzioni dell'app richiedono che
          l'app disponga di una connessione Internet attiva. La connessione può
          essere Wi-Fi o fornita dal tuo gestore di rete mobile, ma Caplaz non
          può assumersi la responsabilità per il mancato funzionamento dell'app
          se non hai accesso al Wi-Fi e non hai nessuno dei tuoi dati indennità
          rimasta.
        </p>
        <p>
          Se stai utilizzando l'app al di fuori di un'area con Wi-Fi, dovresti
          ricordare che i termini dell'accordo con il tuo gestore di rete mobile
          continueranno ad applicarsi. Di conseguenza, il tuo gestore di
          telefonia mobile potrebbe addebitarti il ​​costo dei dati per la
          durata della connessione durante l'accesso all'app o altri costi di
          terze parti. Utilizzando l'app, ti assumi la responsabilità di tali
          addebiti, compresi i costi per i dati in roaming, se utilizzi l'app al
          di fuori del tuo territorio (ad esempio regione o paese) senza
          disattivare il roaming dati. Se non sei il pagatore della bolletta per
          il dispositivo su cui stai utilizzando l'app, tieni presente che
          presupponiamo che tu abbia ricevuto l'autorizzazione dal pagatore
          della bolletta per l'utilizzo dell'app.
        </p>
        <p>
          Allo stesso modo, Caplaz non può sempre assumersi la responsabilità
          del modo in cui utilizzi l'app, ovvero devi assicurarti che il tuo
          dispositivo rimanga carico: se la batteria si esaurisce e non puoi
          accenderlo per usufruire del Servizio, Caplaz non può accettare la
          responsabilità.
        </p>
        <p>
          Per quanto riguarda la responsabilità di Caplaz per l'utilizzo
          dell'app, quando si utilizza l'app, è importante tenere presente che,
          sebbene ci sforziamo di garantire che sia aggiornata e corretta in
          ogni momento, ci affidiamo a terze parti per fornire informazioni a
          noi in modo che possiamo metterle a tua disposizione. Caplaz non si
          assume alcuna responsabilità per eventuali perdite, dirette o
          indirette, che si verificano a seguito dell'affidamento completo a
          questa funzionalità dell'app.
        </p>
        <p>
          Ad un certo punto, potremmo voler aggiornare l'app. L'app è
          attualmente disponibile su Android e iOS: i requisiti per entrambi i
          sistemi (e per eventuali sistemi aggiuntivi a cui decidiamo di
          estendere la disponibilità dell'app) potrebbero cambiare e dovrai
          scaricare gli aggiornamenti se desideri mantenerli utilizzando l'app.
          Caplaz non promette che aggiornerà sempre l'app in modo che sia
          rilevante per te e/o funzioni con la versione Android e iOS che hai
          installato sul tuo dispositivo. Tuttavia, prometti di accettare sempre
          gli aggiornamenti dell'applicazione quando ti vengono offerti,
          potremmo anche voler interrompere la fornitura dell'app e potremmo
          interromperne l'uso in qualsiasi momento senza darti preavviso di
          cessazione. Salvo diversa indicazione da parte nostra, in caso di
          risoluzione, (a) i diritti e le licenze a te concessi in questi
          termini cesseranno; (b) devi interrompere l'utilizzo dell'app e (se
          necessario) eliminarla dal tuo dispositivo.
        </p>
        <p>
          <strong>Modifiche ai presenti termini e condizioni</strong>
        </p>
        <p>
          Potremmo aggiornare i nostri Termini e condizioni di volta in volta.
          Pertanto, si consiglia di rivedere periodicamente questa pagina per
          eventuali modifiche. Ti informeremo di eventuali modifiche pubblicando
          i nuovi termini e condizioni su questa pagina.
        </p>
        <p>
          Questi termini e condizioni entrano in vigore a partire dal 13-04-2022
        </p>
        <p>
          Se hai domande o suggerimenti sulla nostri termini e condizioni, non
          esitare a contattarci all'indirizzo{" "}
          <a href="mailto:info@caplaz.com" className="external-links">
            info@caplaz.com
          </a>
          .
        </p>
      </div>
    </section>
  </Layout>
)

export default TermsPage

export const query = graphql`
  query {
    site {
      ...SiteInformation
    }
  }
`
